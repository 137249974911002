:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.skew {
  -moz-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
}

.unskew {
  -moz-transform: skew(-0deg, 0deg);
  -webkit-transform: skew(-0deg, 0deg);
  -o-transform: skew(-0deg, 0deg);
  -ms-transform: skew(-0deg, 0deg);
  transform: skew(-0deg, 0deg);
}

.circle {
  position: relative;
  /* margin: 20px; */
  /* height: 100px;
  width: 100px; */
  /* background-color: orange; */
  border-radius: 50%;
}

.circle:before {
  position: absolute;
  content: "";
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  border-width: 2px;
  border-style: dashed;
  top: -4px;
  left: 4px;
  border-radius: inherit;
  animation: spin 10s linear infinite;
}

@keyframes flasher {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

/* .fade-in {
  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s;
} */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ui.fixed.menu {
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  );
}

.ui.main.container {
  margin-top: 7em;
}

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  ) !important;
  height: 100vh;
}

.masthead h1.ui.header {
  font-size: 4em;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-calendar {
  width: 100%;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}

.trapdoor {
  -webkit-transform: translateZ(0px);
  -webkit-font-smoothing: antialiased;
  position: relative;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;
  top: 0%;
  /* left: 50%; */
  margin-left: -110px;
  margin-top: 0px;
  width: 255px;
  height: 50px;
  box-shadow: inset -7px 0px 12px -8px rgba(0, 0, 0, 0.3),
    inset 7px 0px 12px -8px rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.3);
  -webkit-transition: background 600ms ease-in-out;
  -moz-transition: background 600ms ease-in-out;
  -ms-transition: background 600ms ease-in-out;
  -o-transition: background 600ms ease-in-out;
  transition: background 600ms ease-in-out;
}

.trapdoor_hover {
  background: transparent;
}

.trapdoor_hover .door {
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.4);
  /* -webkit-transform: scale(1.08);
  -moz-transform: scale(1.08);
  -ms-transform: scale(1.08);
  -o-transform: scale(1.08);
  transform: scale(1.08); */
}

.trapdoor_hover .top {
  left: -50%;
}

.trapdoor_hover .bottom {
  left: 100%;
}

.trapdoor .twitter-follow-button {
  margin-left: -77px;
  position: relative !important;
  margin-top: -14px;
  left: 50%;
  top: 50%;
}

.top {
  top: -2px;
  left: 0%;
  left: 0px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.top:before {
  top: 5px;
}

.bottom {
  top: -2px;
  left: 50%;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.bottom:before {
  top: -20px;
}

.door {
  -webkit-transition: left 400ms, box-shadow 200ms, -webkit-transform 300ms;
  -moz-transition: left 400ms, box-shadow 200ms, -moz-transform 300ms;
  -ms-transition: left 400ms, box-shadow 200ms, -ms-transform 300ms;
  -o-transition: left 400ms, box-shadow 200ms, -o-transform 300ms;
  transition: left 400ms, box-shadow 200ms, transform 300ms;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  position: absolute;
  overflow: hidden;
  width: 50%;
  z-index: 2;
  height: 100%;
  top: -2px;
  background-image: linear-gradient(
    135deg,
    #ff790e 25%,
    #5f5370 25%,
    #5f5370 50%,
    #ff790e 50%,
    #ff790e 75%,
    #5f5370 75%,
    #5f5370 100%
  );
  background-size: 42.43px 42.43px;
  border: 1px solid #000;
}

/* .door:before {
} */

@font-face {
  font-family: "tweet";
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/277/tweet.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

.stripes {
  background-image: linear-gradient(
    35deg,
    #ff780e 25%,
    #5f5370 25%,
    #5f5370 50%,
    #ff780e 50%,
    #ff780e 75%,
    #5f5370 75%,
    #5f5370 100%
  );
  background-size: 42.43px 42.43px;
}

.smoothresize {
  -webkit-transition: height 400ms;
  -moz-transition: height 400ms;
  -ms-transition: height 400ms;
  -o-transition: height 400ms;
  transition: height 400ms;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.led {
  margin: 0 auto;
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
  margin-top: 2px;
  margin-bottom: -2px;
}

.led.green {
  background-color: #abff00;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 6px,
    #89ff00 0 2px 18px;
}

.led.blue {
  background-color: #24e0ff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px,
    #3f8cff 0 2px 18px;
}

.led.red {
  background-color: #24e0ff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
    rgba(255, 0, 0, 0.5) 0 2px 18px;
}

.led-red {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #f00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
    rgba(255, 0, 0, 0.5) 0 2px 12px;
  -webkit-animation: blinkRed 0.5s infinite;
  -moz-animation: blinkRed 0.5s infinite;
  -ms-animation: blinkRed 0.5s infinite;
  -o-animation: blinkRed 0.5s infinite;
  animation: blinkRed 0.5s infinite;
}

@-webkit-keyframes blinkRed {
  from {
    background-color: #f00;
  }
  50% {
    background-color: #a00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
      rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #f00;
  }
}
@-moz-keyframes blinkRed {
  from {
    background-color: #f00;
  }
  50% {
    background-color: #a00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
      rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #f00;
  }
}
@-ms-keyframes blinkRed {
  from {
    background-color: #f00;
  }
  50% {
    background-color: #a00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
      rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #f00;
  }
}
@-o-keyframes blinkRed {
  from {
    background-color: #f00;
  }
  50% {
    background-color: #a00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
      rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #f00;
  }
}
@keyframes blinkRed {
  from {
    background-color: #f00;
  }
  50% {
    background-color: #a00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
      rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #f00;
  }
}

.led-yellow {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #ff0;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
    #ff0 0 2px 12px;
  -webkit-animation: blinkYellow 1s infinite;
  -moz-animation: blinkYellow 1s infinite;
  -ms-animation: blinkYellow 1s infinite;
  -o-animation: blinkYellow 1s infinite;
  animation: blinkYellow 1s infinite;
}

@-webkit-keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
      #ff0 0 2px 0;
  }
  to {
    background-color: #ff0;
  }
}
@-moz-keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
      #ff0 0 2px 0;
  }
  to {
    background-color: #ff0;
  }
}
@-ms-keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
      #ff0 0 2px 0;
  }
  to {
    background-color: #ff0;
  }
}
@-o-keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
      #ff0 0 2px 0;
  }
  to {
    background-color: #ff0;
  }
}
@keyframes blinkYellow {
  from {
    background-color: #ff0;
  }
  50% {
    background-color: #aa0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
      #ff0 0 2px 0;
  }
  to {
    background-color: #ff0;
  }
}

.bubble {
  position: relative;
  max-width: 500px;
  border: 2px solid;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 1.125em 1.5em;
  /* color: #000; */
  border-radius: 15px;
}

/* .bubble::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: 100%;
  left: 30px;
  border: 15px solid transparent;
  border-top: none;

  border-bottom-color: rgba(255, 255, 255, 0.5);
} */

.rolling {
  animation: blinkingText 1.2s infinite;
}

@keyframes blinkingText {
  0% {
    color: #000;
  }
  49% {
    color: #000;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #000;
  }
}

.canvas {
  position: fixed;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* thanos disappear */

.thanosvanish {
  animation: thanosvanish 3s linear;
  animation-fill-mode: forwards;
  pointer-events: none;
}
.thanosvanish.die {
  opacity: 0;
}

@keyframes thanosvanish {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
    filter: blur(0px);
    opacity: 1;
  }
  50% {
    transform: scale(1.5) translate(50px, -10px) rotate(60deg);
  }
  100% {
    transform: translate(80px, -20px) rotate(90deg);
    filter: blur(40px);
    opacity: 0;
  }
}

#crumbs {
  text-align: center;
}
#crumbs ul {
  list-style: none;
  display: flex;
  width: 100%;
}
#crumbs ul li {
  display: inline;
  width: 100%;
}
#crumbs .crumbitembase {
  /* background: #333842; */
  /* color: #8093a7; */
  height: 40px;
  line-height: 20px;
  padding: 10px 20px 0 30px;
  margin: 1px;
  /* font-size: 14px; */
  text-align: center;
  position: relative;
  text-decoration: none;
  display: block;
}
#crumbs .crumbitem {
  float: left;
  width: 100%;
  margin: 0 10px 0 0;
}
#crumbs .crumbitem:after {
  content: "";
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left-style: solid;
  border-left-width: 20px;
  position: absolute;
  right: -20px;
  top: 0;
  z-index: 1;
}
#crumbs .crumbitem:before {
  content: "";
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left-style: solid;
  border-left-width: 20px;
  position: absolute;
  left: 0;
  top: 0;
}

#crumbs .crumbitem:first-child:before {
  display: none;
}

#crumbs .crumbitem:last-child {
  margin-right: 0px;
}

#crumbs .crumbitem:last-child:after {
  display: none;
}

#crumbs .crumbitem.clickable {
  cursor: pointer;
}

.legal h1 {
  font-size: 40px !important;
  margin-top: 20px !important;
}
.legal h2,
h3 {
  font-size: 24px !important;
  margin-top: 10px !important;
}

.legal ul {
  list-style-type: disc;
  padding-left: 30px;
  margin-top: 5px;
}
.legal li {
  padding-left: 5px;
}
